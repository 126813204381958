const letters = document.querySelectorAll(".shuffle-colors [class^=text-]");
let colorArray = [];
let currentClasslist;
let newClasslist;
letters.forEach((letter) => {
	colorArray.push(Array.from(letter.classList));
});
colorArray = colorArray.flat();

const colorChange = setInterval(function () {
	newColorArray = colorArray.sort(() => Math.random() - 0.5);
	letters.forEach((letter, i) => {
		letter.classList.remove(...colorArray);
		letter.classList.add(newColorArray[i]);
	});
}, 1000);

setTimeout(function () {
	clearInterval(colorChange)
}, 20000);